import crudMutations from "@/store/templates/crud/mutations";

export default {
  ...crudMutations,
  setName(state, name) {
    state.model.name = name;
  },
  setRedirectType(state, redirectType) {
    state.model.redirectType = redirectType;
  },
  setRedirectUrl(state, redirectUrl) {
    state.model.redirectUrl = redirectUrl;
  },
  setLocales(state, locales) {
    state.model.locales = locales;
  },
  openAnnotationDialog(state, isOpen) {
    state.isAnnotationDialogOpen = isOpen;
  },
  setAnnotationDialogId(state, id) {
    state.annotationDialogId = id;
  }
};
